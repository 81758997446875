<template>
  <v-switch
    v-if="$route.name === 'community-collections'"
    v-model="selectedVal"
    :disabled="isActiveUserGuestInWorkspace"
    class="ma-0 pa-0"
    color="black"
    inset
    hide-details>
    <template #label>
      <span class="fs-12 font-weight-bold text-uppercase black--text">
        Followed Only
      </span>
    </template>
  </v-switch>
</template>

<script>
import {
  mapGetters, mapMutations,
} from 'vuex';

export default {
  name: 'SearchFilterWorkspaceOnly',
  computed: {
    ...mapGetters(['getIsWorkspaceOnlySearch']),
    ...mapGetters('Workspace', ['isActiveUserGuestInWorkspace']),
    selectedVal: {
      get() {
        return this.getIsWorkspaceOnlySearch;
      },
      set(val) {
        this.toggleIsWorkspaceOnlySearch(val);
      },
    },
  },
  mounted() {
    if (this.isActiveUserGuestInWorkspace) {
      this.toggleIsWorkspaceOnlySearch(true);
    }
  },
  methods: {
    ...mapMutations(['toggleIsWorkspaceOnlySearch']),
  },
};
</script>